import { Dimension, WidgetTypes } from "@superblocksteam/shared";
import { EmptyMargin } from "legacy/components/propertyControls/MarginControl";
import {
  MarginIconPrefix,
  PaddingIconPrefix,
} from "legacy/components/utils/IconUtil";
import {
  PropsPanelCategory,
  type Hidden,
  type PropertyPaneControlConfig,
  type PropertyPaneSectionConfig,
} from "legacy/constants/PropertyControlConstants";
import {
  CanvasDefaults,
  CanvasLayout,
  GridDefaults,
  WidgetLabelPosition,
} from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import { CanvasWidgetsReduxState } from "legacy/reducers/entityReducers/canvasWidgetsReducer";
import {
  getCanvasWidgets,
  isColumnUtil,
} from "legacy/selectors/entitiesSelector";
import { LABEL_POSITION_PROPERTY_CONTROL_HELP_TEXT } from "pages/Editors/AppBuilder/Sidebar/PropertyControlCommons";
import { Flag, Flags } from "store/slices/featureFlags";
import { WidgetProps } from "./BaseWidget";
import { isStackLayout } from "./StackLayout/utils";
import { getWidgetDefaultPadding, ZERO_PADDING } from "./base/sizing";
import {
  heightModeSupportsMinMax,
  widthModeSupportsMinMax,
} from "./layoutProperties";

const hideWidthMode = (
  supportFitContent: boolean,
  hideWidthMode: boolean,
  props: any,
  path: string,
  flags: Flags,
) => {
  if (hideWidthMode) return true;
  return false;
};

export const sizeProperties = (
  props: {
    heightSupportsFitContent?: boolean | ((flags: Flags) => boolean);
    widthSupportsFitContent?: boolean;
    hideWidthMode?: boolean;
    hideMinMaxHeightSection?: boolean;
    hideMinMaxWidthSection?: boolean;
    hideMargin?: boolean;
  } = {},
) => {
  const options = [
    {
      propertyName: "width",
      propertyCategory: PropsPanelCategory.Layout,
      label: "Width",
      controlType: "WIDTH_DROPDOWN",
      isBindProperty: true,
      isTriggerProperty: false,
      hidden: hideWidthMode.bind(
        null,
        props.widthSupportsFitContent ?? false,
        props.hideWidthMode ?? false,
      ),
      supportFitContent: props.widthSupportsFitContent,
    },
    ...(!props.hideMinMaxWidthSection ? minMaxWidthProperties() : []),
    {
      propertyName: "height",
      propertyCategory: PropsPanelCategory.Layout,
      label: "Height",
      controlType: "HEIGHT_DROPDOWN",
      isBindProperty: true,
      isTriggerProperty: false,
      getAdditionalHiddenData: {
        canvasWidgets: getCanvasWidgets,
      },
      supportFitContent: props.heightSupportsFitContent,
    },
    ...(!props.hideMinMaxHeightSection ? minMaxHeightProperties() : []),
    ...(!props.hideMargin ? [marginProperty()] : []),
  ];
  return options satisfies PropertyPaneControlConfig<any>[];
};

export const sizeSection = (
  props: {
    heightSupportsFitContent?: boolean | ((flags: Flags) => boolean);
    widthSupportsFitContent?: boolean;
    hideMinMaxHeightSection?: boolean;
    hideMinMaxWidthSection?: boolean;
    hideMargin?: boolean;
  } = {},
): PropertyPaneSectionConfig => ({
  sectionName: "Size",
  sectionCategory: PropsPanelCategory.Layout,
  getAdditionalHiddenData: {
    canvasWidgets: getCanvasWidgets,
  },
  hidden: (_props, path, flags, additionalHiddenData) => {
    const isColumn = additionalHiddenData?.canvasWidgets
      ? isColumnUtil(additionalHiddenData.canvasWidgets, _props.widgetId)
      : false;
    if (
      // if it is tab canvas
      _props.type === WidgetTypes.CANVAS_WIDGET &&
      !isColumn
    ) {
      return true;
    }
    return false;
  },
  children: [...sizeProperties(props)],
});

const minMaxHeightProperties = (): PropertyPaneControlConfig[] => [
  {
    propertyName: "minHeight",
    propertyCategory: PropsPanelCategory.Layout,
    label: "Min height",
    hidden: (props: WidgetProps, propertyPath, flags: Flags) => {
      return !heightModeSupportsMinMax(props.height.mode);
    },
    visibility: "SHOW_NAME",
    isRemovable: true,
    defaultValue: Dimension.px(100),
    controlType: "DIMENSION_VALUE_CONTROL",
    isBindProperty: false,
    isTriggerProperty: false,
    dimensionUnit: "px",
    parentSpace: GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
    minValue: GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
  },
  {
    propertyName: "maxHeight",
    propertyCategory: PropsPanelCategory.Layout,
    label: "Max height",
    hidden: (props: WidgetProps, propertyPath, flags: Flags) => {
      return !heightModeSupportsMinMax(props.height.mode);
    },
    visibility: "SHOW_NAME",
    isRemovable: true,
    defaultValue: Dimension.px(100),
    controlType: "DIMENSION_VALUE_CONTROL",
    isBindProperty: false,
    isTriggerProperty: false,
    dimensionUnit: "px",
    parentSpace: GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
    minValue: GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
  },
];

const shouldHideMinMaxWidth: PropertyPaneControlConfig["hidden"] = (
  props: WidgetProps,
  propertyPath,
  flags: Flags,
  additional,
) => {
  const canvasWidgets = additional?.canvasWidgets as CanvasWidgetsReduxState;
  const parent = canvasWidgets?.[props.parentId ?? ""];
  const parentIsStack = isStackLayout(parent?.layout);
  return !widthModeSupportsMinMax(props.width.mode) || !parentIsStack;
};

const minMaxWidthGetAdditionalHiddenData = {
  canvasWidgets: getCanvasWidgets,
};

const minMaxWidthProperties = (): PropertyPaneControlConfig[] => [
  {
    propertyName: "minWidth",
    propertyCategory: PropsPanelCategory.Layout,
    label: "Min width",
    hidden: shouldHideMinMaxWidth,
    getAdditionalHiddenData: minMaxWidthGetAdditionalHiddenData,
    visibility: "SHOW_NAME",
    isRemovable: true,
    defaultValue: Dimension.px(120), // these values don't matter: they get overridden to the current width in widget ops
    controlType: "DIMENSION_VALUE_CONTROL",
    isBindProperty: false,
    isTriggerProperty: false,
    dimensionUnit: "px",
    minValue: CanvasDefaults.MIN_GRID_UNIT_WIDTH,
  },
  {
    propertyName: "maxWidth",
    propertyCategory: PropsPanelCategory.Layout,
    label: "Max width",
    hidden: shouldHideMinMaxWidth,
    getAdditionalHiddenData: minMaxWidthGetAdditionalHiddenData,
    visibility: "SHOW_NAME",
    isRemovable: true,
    defaultValue: Dimension.px(240), // also overridden
    controlType: "DIMENSION_VALUE_CONTROL",
    isBindProperty: false,
    isTriggerProperty: false,
    dimensionUnit: "px",
    minValue: CanvasDefaults.MIN_GRID_UNIT_WIDTH,
  },
];

export const minMaxWidthPageProperties = (): PropertyPaneControlConfig[] => [
  {
    propertyName: "minWidth",
    propertyCategory: PropsPanelCategory.Layout,
    label: "Min width",
    visibility: "SHOW_NAME",
    isRemovable: true,
    defaultValue: Dimension.px(300),
    controlType: "DIMENSION_VALUE_CONTROL",
    isBindProperty: false,
    isTriggerProperty: false,
    dimensionUnit: "px",
  },
  {
    propertyName: "maxWidth",
    propertyCategory: PropsPanelCategory.Layout,
    label: "Max width",
    visibility: "SHOW_NAME",
    isRemovable: true,
    defaultValue: Dimension.px(7680),
    controlType: "DIMENSION_VALUE_CONTROL",
    isBindProperty: false,
    isTriggerProperty: false,
    dimensionUnit: "px",
  },
];

export const visibilitySection = ({
  useJsExpr = true,
}: {
  useJsExpr?: boolean;
}): PropertyPaneSectionConfig => ({
  sectionName: "Visibility",
  sectionCategory: PropsPanelCategory.Appearance,
  children: [...visibleProperties({ useJsExpr })],
});

export const visibleProperties = (
  { useJsExpr = true }: { useJsExpr?: boolean }, // All new widgets should use JS expressions, old ones should not
): PropertyPaneControlConfig[] => [
  isVisibleProperty({ useJsExpr }),

  collapseWhenHiddenProperty({}),
];

export const isVisibleProperty = (
  { useJsExpr = true, hidden }: { useJsExpr?: boolean; hidden?: Hidden }, // All new widgets should use JS expressions, old ones should not
): PropertyPaneControlConfig => ({
  propertyName: "isVisible",
  propertyCategory: PropsPanelCategory.Layout,
  label: "Visible",
  helpText: "Whether the component is visible",
  controlType: "SWITCH",
  isJSConvertible: true,
  hidden,
  isBindProperty: true,
  isTriggerProperty: false,
  validation: VALIDATION_TYPES.BOOLEAN,
  customJSControl: useJsExpr ? "INPUT_JS_EXPR" : undefined,
});

export const collapseWhenHiddenProperty = ({
  hidden,
}: {
  hidden?: Hidden;
}): PropertyPaneControlConfig => ({
  propertyName: "collapseWhenHidden",
  propertyCategory: PropsPanelCategory.Layout,
  label: "Collapse when invisible",
  helpText:
    "When enabled, other components shift up to occupy the space left by this component. If disabled, the component is hidden but leaves blank space. Has no effect when viewing in edit mode",
  controlType: "SWITCH",
  getAdditionalHiddenData: {
    canvasWidgets: getCanvasWidgets,
  },
  hidden,
  isJSConvertible: false,
  isBindProperty: true,
  isTriggerProperty: false,
  validation: VALIDATION_TYPES.BOOLEAN,
});

export const marginProperty = () =>
  ({
    propertyName: "margin",
    propertyCategory: PropsPanelCategory.Layout,
    label: "Margin",
    controlType: "MARGIN_CONTROL",
    isBindProperty: false,
    isTriggerProperty: false,
    helpText: "The outer spacing of the component",
    visibility: "SHOW_NAME",
    isRemovable: true,
    defaultValue: EmptyMargin,
    labelIconPrefix: MarginIconPrefix,

    hidden: (
      props: WidgetProps,
      _propertyPath: string,
      flags: Flags,
      additionalHiddenData: any,
    ) => {
      if (!flags[Flag.LAYOUTS_ENABLE_MARGIN]) return true;

      const canvasWidgets =
        additionalHiddenData?.canvasWidgets as CanvasWidgetsReduxState;
      const parent = canvasWidgets?.[props.parentId ?? ""];

      if (!parent) return true;
      const parentIsStack =
        parent.layout === CanvasLayout.HSTACK ||
        parent.layout === CanvasLayout.VSTACK;
      return !parentIsStack;
    },
    getAdditionalHiddenData: {
      canvasWidgets: getCanvasWidgets,
    },
  } satisfies PropertyPaneControlConfig);

export const paddingProperty = (
  overrides: Partial<PropertyPaneControlConfig> = {},
) =>
  ({
    propertyName: "padding",
    propertyCategory: PropsPanelCategory.Layout,
    label: "Padding",
    controlType: "PADDING_CONTROL",
    isBindProperty: false,
    isTriggerProperty: false,
    isRemovable: true,
    visibility: "SHOW_NAME",
    labelIconPrefix: PaddingIconPrefix,
    themeValue: ({ theme, props }) => {
      const value = getWidgetDefaultPadding(theme, props);
      const treatAsNull = value === ZERO_PADDING;
      return {
        treatAsNull,
        value: value,
      };
    },
    ...overrides,
  } satisfies PropertyPaneControlConfig);

export const labelPositionProperty = <T>(opts: {
  orientation: "legacy" | "horizontal";
  defaultValue?: WidgetLabelPosition;
}) => {
  let options = [];
  let defaultValue;
  let propertyName: string;
  switch (opts.orientation) {
    case "legacy":
      // Legacy: keeps the inner `isVertical` boolean prop control.
      // legacy: boolean options displayed as radio button group
      propertyName = "isVertical";
      defaultValue = true;
      options = [
        {
          value: true,
          label: "Above",
          icon: "VERTICAL_TOP",
        },
        {
          value: false,
          label: "Beside",
          icon: "ICON_LEFT_ALIGN",
        },
      ];
      break;
    case "horizontal":
      propertyName = "labelPosition";
      defaultValue = opts.defaultValue ?? WidgetLabelPosition.RIGHT;
      options = [
        {
          label: "Left",
          value: WidgetLabelPosition.LEFT,
          icon: "ICON_LEFT_ALIGN",
        },
        {
          label: "Right",
          value: WidgetLabelPosition.RIGHT,
          icon: "ICON_RIGHT_ALIGN",
        },
      ];
      break;
  }

  return {
    propertyName,
    label: "Label position",
    helpText: LABEL_POSITION_PROPERTY_CONTROL_HELP_TEXT,
    controlType: "RADIO_BUTTON_GROUP",
    hidden: (props: { label?: string }) => !props.label,
    options,
    defaultValue,
    isJSConvertible: true,
    isBindProperty: true,
    isTriggerProperty: false,
    propertyCategory: PropsPanelCategory.Appearance,
  } satisfies PropertyPaneControlConfig as unknown as PropertyPaneControlConfig<T>;
};
