import { Classes as BlueprintClasses } from "@blueprintjs/core";
import { TextStyleBlock } from "@superblocksteam/shared";
import { css } from "styled-components";
import { CLASS_NAMES } from "../classnames";
import { GeneratedTheme } from "../types";
import {
  activeStyles,
  checkboxStyleFromStyleBlock,
  disabledStyles,
  errorStyles,
  inputStyleFromStyleBlock,
  radioStyleFromStyleBlock,
  styleFromTextBlockWithColors,
  switchStyleFromStyleBlock,
} from "./utils";

export const generateInputStyles = (
  inputStyles: GeneratedTheme["inputs"],
  placeholderStyle: TextStyleBlock,
) => {
  const val = css`
    .${CLASS_NAMES.CHECKBOX} {
      ${checkboxStyleFromStyleBlock(inputStyles.checkbox)}
    }

    .${CLASS_NAMES.SWITCH} {
      ${switchStyleFromStyleBlock(inputStyles.switch)}
    }

    .${CLASS_NAMES.RADIO} {
      ${radioStyleFromStyleBlock(inputStyles.radio)}
    }
    textarea.${CLASS_NAMES.INPUT}, input.${CLASS_NAMES.INPUT} {
      ${inputStyleFromStyleBlock(inputStyles.input)}
    }

    .ant-form-item-has-error input.${CLASS_NAMES.INPUT} {
      ${errorStyles(inputStyles.input)}
    }

    .${CLASS_NAMES.INPUT} {
      input,
      textarea {
        ${inputStyleFromStyleBlock(inputStyles.input)}
      }
      &.${CLASS_NAMES.DISABLED_MODIFIER} {
        input,
        textarea {
          ${disabledStyles(inputStyles.input)}
        }
      }
      &.${CLASS_NAMES.ERROR_MODIFIER} {
        input,
        textarea {
          ${errorStyles(inputStyles.input)}
        }
      }
    }
    .${CLASS_NAMES.TAG_INPUT} {
      .${BlueprintClasses.INPUT_GHOST} {
        &::placeholder {
          ${styleFromTextBlockWithColors(placeholderStyle)}
        }
      }
      .${BlueprintClasses.INPUT} {
        ${inputStyleFromStyleBlock(inputStyles.input)}
        padding: 0px 3px;
        &.${BlueprintClasses.ACTIVE} {
          ${activeStyles(inputStyles.input)}
        }
      }
      &.${CLASS_NAMES.ERROR_MODIFIER} {
        .${BlueprintClasses.INPUT} {
          ${errorStyles(inputStyles.input)}
        }
      }
      &.${CLASS_NAMES.DISABLED_MODIFIER} {
        .${BlueprintClasses.INPUT} {
          ${disabledStyles(inputStyles.input)}
        }
      }
      &.${BlueprintClasses.ACTIVE} {
        .${BlueprintClasses.INPUT} {
          ${activeStyles(inputStyles.input)}
        }
      }
    }

    .${CLASS_NAMES.INPUT} {
      .tox-tinymce {
        ${inputStyleFromStyleBlock(inputStyles.input)}
      }
      &.${CLASS_NAMES.ERROR_MODIFIER} {
        .tox-tinymce {
          ${errorStyles(inputStyles.input)}
        }
      }
    }
  `;
  return val.join("");
};
