import { ThemeMode } from "@superblocksteam/shared";
import { css } from "styled-components";
import { generatePaddingCss } from "legacy/widgets/base/generatePaddingStyle";
import { CLASS_NAMES } from "../classnames";
import { GeneratedTheme } from "../types";
import {
  borderlessStyleFromStyleBlock,
  containerBorderOutline,
  generateBorderStyle,
  iconStyleFromStyleBlock,
  noBackgroundOrBorderStyleFromStyleBlock,
  staticStyleFromStyleBlock,
  styleFromDimension,
  styleFromTextBlockWithColors,
} from "./utils";

export const generateContainerStyles = (theme: GeneratedTheme) => {
  const containerStyles = theme.container;
  return css`
    .${CLASS_NAMES.SECTION} {
      background: ${staticStyleFromStyleBlock(containerStyles.section)};
    }
    .${CLASS_NAMES.ROUNDED_CONTAINER} {
      border-radius: ${styleFromDimension(
        containerStyles.default.borderRadius,
      )};
    }
    .${CLASS_NAMES.CANVAS} {
      ${staticStyleFromStyleBlock(containerStyles.canvas)}
    }
    .${CLASS_NAMES.DEFAULT_CONTAINER} {
      ${staticStyleFromStyleBlock(containerStyles.default)}
    }
    .${CLASS_NAMES.DEFAULT_CONTAINER_STYLE_CARD} {
      ${borderlessStyleFromStyleBlock(containerStyles.default)}
    }
    .${CLASS_NAMES.DEFAULT_CONTAINER_STYLE_NONE} {
      ${noBackgroundOrBorderStyleFromStyleBlock(containerStyles.default)}
    }
    .${CLASS_NAMES.DEFAULT_CONTAINER_BORDER} {
      border: ${generateBorderStyle(containerStyles.default)};
    }
    .${CLASS_NAMES.CONTAINER_BORDER_OUTLINE} {
      ${containerBorderOutline(containerStyles.default)}
    }
    .${CLASS_NAMES.MODAL} {
      .ant-modal-content {
        ${staticStyleFromStyleBlock(containerStyles.modal)}
      }
    }
    .${CLASS_NAMES.SLIDEOUT} {
      .ant-drawer-content {
        ${staticStyleFromStyleBlock(containerStyles.canvas)}
        padding: 0px; // canvas will have padding, so remove it here
        background: ${containerStyles.default.backgroundColor.default};
      }
    }
    .${CLASS_NAMES.CLOSE_ICON} {
      ${iconStyleFromStyleBlock(containerStyles.closeIcon)}
    }

    .${CLASS_NAMES.TAB} {
      ${styleFromTextBlockWithColors(containerStyles.tab)}
      ${generatePaddingCss(containerStyles.tab.padding)};
      border-bottom: ${generateBorderStyle(containerStyles.tab)};
      &.${CLASS_NAMES.ACTIVE_MODIFIER} {
        color: ${containerStyles.tab.textColor.active};
      }
      &::after {
        background-color: ${containerStyles.tab.borderColor.active};
      }
    }
    .${CLASS_NAMES.STYLED_SCROLLBAR} {
      @supports (scrollbar-color: auto) {
        // currently supported by firefox only. chrome support Jan 2024
        scrollbar-width: thin;
      }
      @supports not (scrollbar-color: auto) {
        &::-webkit-scrollbar {
          width: 7px;
          height: 7px;
        }
        &::-webkit-scrollbar-track {
          // mimic the built-in chrome scrollbar w/ the colors
          background-color: ${theme.mode === ThemeMode.DARK
            ? "#2b2b2b"
            : "#fafafa"};
          &:vertical {
            border-right: 1px solid
              ${theme.mode === ThemeMode.DARK ? "#3d3d3d" : "#e8e8e8"};
            border-left: 1px solid
              ${theme.mode === ThemeMode.DARK ? "#3d3d3d" : "#e8e8e8"};
          }
          &:horizontal {
            border-right: 1px solid
              ${theme.mode === ThemeMode.DARK ? "#3d3d3d" : "#e8e8e8"};
            border-left: 1px solid
              ${theme.mode === ThemeMode.DARK ? "#3d3d3d" : "#e8e8e8"};
          }
        }
        &::-webkit-scrollbar-thumb {
          // mimic the built-in chrome scrollbar w/ the colors
          background-color: ${theme.mode === ThemeMode.DARK
            ? "#6b6b6b"
            : "#c7c7c7"};
          border-radius: 4px;
          border: 1px solid transparent;
          background-clip: content-box;
          &:hover {
            background-color: ${theme.mode === ThemeMode.DARK
              ? "#818181"
              : "#747474"};
          }
        }
        &::-webkit-scrollbar-corner {
          // mimic the built-in chrome scrollbar w/ the colors
          background-color: ${theme.mode === ThemeMode.DARK
            ? "#2b2b2b"
            : "#fafafa"};
        }
      }
    }
  `.join("");
};
